<template>
  <v-container fluid class="fill-height pa-0">
    <router-view />
  </v-container>
</template>

<script>
  export default {
    name: 'app-wrapper',
  };
</script>
